import * as Sentry from '@sentry/react'

if (process.env.JEST_WORKER_ID) {
} else if (!process.env.CLIENT_SENTRY_DSN) {
  console.error('Missing CLIENT_SENTRY_DSN, Sentry is disabled for now')
} else {
  const dsn = process.env.CLIENT_SENTRY_DSN
  const environment = process.env.SENTRY_ENV
  const release = `web@${process.env.SOURCE_VERSION}`
  Sentry.init({
    dsn,
    release,
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // turns off all the magic error capture
    // defaultIntegrations: false,
    tracesSampleRate: 1.0,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  })
}

export default Sentry
