import React, { useEffect } from 'react'
import type { PropsWithChildren } from 'react'
import type {
  AppBodyQuery,
  AppBodyQueryVariables,
  UserViewMutation,
  UserViewMutationVariables
} from 'types/graphql'

import AppContext, { useAppContextValue } from 'src/lib/appContext'
import { useMutation, type CellSuccessProps } from '@redwoodjs/web'

import { MetadataSchemaContext, MetadataSchema } from 'src/lib/metadata'
import NavBar from './NavBar'

export const QUERY = gql`
  query AppBodyQuery {
    currentOrg {
      id
      metadataSchema
      distributors {
        id
        name
      }
      lifeCycleStages {
        key
        name
        color
      }
      defaultPartVersion
    }
    partNumberSchemas {
      key
      templateConfig
      categories {
        id
        name
      }
    }
    partCategories {
      id
      name
      label
      useRange
      schema {
        key
        template
        templateConfig
      }
    }
    me {
      id
      name
      latestNotification
      email
      notificationsReadAt
      isSuperUser
      defaultOrgId
      memberOf {
        orgId
        role
      }
    }
  }
`

export const VIEW_MUTATION = gql`
  mutation UserViewMutation {
    updateUserLastLoggedInView
  }
`

// TODO: Switch Navbar to a cell - it is not necessary for
// this to be a cell, it only serves to pass navbar its
// data right now

export const Loading = ({ children, width }: PropsWithChildren<{width?: 'full'}>) => {
  return <AppBody width={width}>{children}</AppBody>
}

export const Success = ({
  children,
  width,
  ...props
}: PropsWithChildren<CellSuccessProps<AppBodyQuery, AppBodyQueryVariables>> & { width?: 'full' }) => {
  return <AppBody width={width} data={props} metadataSchema={props.currentOrg.metadataSchema as MetadataSchema}>
      {children}
    </AppBody>
}

type AppBodyProps = PropsWithChildren<{
  data?: CellSuccessProps<AppBodyQuery, AppBodyQueryVariables> | undefined
  metadataSchema?: MetadataSchema,
  width?: 'full'
}>

const AppBody = ({children, data, metadataSchema, width}: AppBodyProps) => {
  const [userView] = useMutation<
    UserViewMutation,
    UserViewMutationVariables
  >(VIEW_MUTATION)
  useEffect(() => {
    userView()
  }, [])
  return <AppContext.Provider value={useAppContextValue(data)}>
    <MetadataSchemaContext.Provider value={metadataSchema as MetadataSchema}>
      <NavBar me={data?.me} key='stable' />
        <main className={width === 'full' ? 'pb-10 max-w-6xl' : 'px-16 max-w-screen-2xl mx-auto pb-10'}>
          {children}
        </main>
    </MetadataSchemaContext.Provider>
  </AppContext.Provider>
}
