import { createContext, useContext } from 'react';

import type { MetadataType } from 'types/graphql'
import type { MetadataSchema } from 'api/src/services/organizations/organizations'
import type { MetadataUnion } from 'api/src/services/changeOrders/changeOrders'

export type { MetadataSchema }

export type ResolvedMetadata = MetadataUnion & { key: string }

export type MetadataValue = MetadataUnion['entry']

export const resolveMetadata = (schema: any, metadata: Record<string, MetadataValue>) => {
  if (!schema) return []
  return Object.entries(metadata).map(([key, value]) => {
    const schemaEntry = (schema as MetadataSchema)[key];

    if (!schemaEntry) {
      throw new Error(`Unknown metadata type: ${key}`)
    }

    return {
      ...schemaEntry,
      key,
      entry: value
    } as ResolvedMetadata
  })
}

export const unresolveMetadata = (resolvedMetadata: ResolvedMetadata[]) => {
  return resolvedMetadata.reduce((output, resolved) => {
    return {
      ...output,
      [resolved.key]: resolved.entry
    }
  }, {} as  Record<string, MetadataValue>)
}

export const castBomMetadataToMetadata = (schema: any, metadata: Record<string, string>) => {
  return unresolveMetadata(castBomMetadataToResolvedMetadata(schema, metadata))
}

export const castBomMetadataToResolvedMetadata = (schema: any, metadata: Record<string, string>) => {
  if (!schema) return []
  return Object.entries(metadata).map(([key, value]) => {
    const schemaEntry = (schema as MetadataSchema)[key]

    if (!schemaEntry) {
      throw new Error(`Unknown metadata type: ${key}`)
    }

    let entry
    if (schemaEntry.type === 'Boolean') {
      entry = /true/gi.test(value)
    }
    if (schemaEntry.type === 'Number') {
      entry = Number(value)
    }

    // converts value-unit string into components
    // e.g. 1.0-USD -> value: 1, unit: USD
    // e.g. 5-KG -> value: 5, unit: KG
    if (schemaEntry.type === 'Mass' || schemaEntry.type === 'Price') {
      const rValueUnit = /^(?<value>\d+\.?\d*)-(?<unit>\w+)$/
      const result = value.match(rValueUnit)?.groups

      const outputValue = Number(result?.value)
      const outputUnit = result?.unit

      if (!result?.value || Number.isNaN(outputValue)) {
        throw new Error(`Error casting mass or price value for input: ${value}`)
      }
      if (!outputUnit) {
        throw new Error(`Error casting mass or price unit for input: ${value}`)
      }
      entry = { value: outputValue, unit: outputUnit }
    }

    if (schemaEntry.type === 'URL' || schemaEntry.type === 'String') {
      entry = value
    }

    // Time not implemented yet
    // if (schemaEntry.type === 'Time')

    return {
      ...schemaEntry,
      key,
      entry: value
    } as ResolvedMetadata
  })
}

export const resolveMetadataObj = (schema: any, metadata: Record<string, MetadataValue>) => {
  if (!schema) return {}
  return Object.entries(metadata).reduce((acc, [key, value]) => {
    const schemaEntry = (schema as MetadataSchema)[key];

    if (!schemaEntry) {
      throw new Error(`Unknown metadata type: ${key}`)
    }

    acc[key] = {
      ...schemaEntry,
      key,
      entry: value
    } as ResolvedMetadata
    return acc;
  }, {} as Record<string, ResolvedMetadata | undefined>)
}

export const metadataTypes = [
  'String', 'Boolean', 'Number', 'URL', 'Price', 'Mass', 'Time'
] as const as MetadataType[]


export const MetadataSchemaContext = createContext<MetadataSchema | undefined>(undefined);

export const useMetadataSchema = () => {
  return useContext(MetadataSchemaContext);
}

export const useMetadataResolver = () => {
  const schema = useMetadataSchema();
  return [(metadata: any) => resolveMetadata(schema, metadata), schema!] as const;
}

export const useMetadataTabularizer = () => {
  const schema = useMetadataSchema();
  return (metadata: any) => tabularizeMetadata(schema!, metadata)
}

export const tabularizeMetadata = (schema: MetadataSchema, metadata: any) => {
  const resolved = resolveMetadataObj(schema, metadata)
  return Object.entries(schema).reduce((acc, [key, def]) => {
    const displayName = def.displayName.replace(' ', '-')
    //acc[`Metadata-${displayName}-Type`] = def.type;

    if (def.type === 'Mass' || def.type === 'Price') {
      const m = resolved[key] as Extract<ResolvedMetadata, { type: 'Mass' | 'Price' }> | undefined;
      acc[`${displayName}-Value`] = m?.entry.value.toString() ?? ''
      acc[`${displayName}-Units`] = m?.entry.unit ?? ''
    }
    else {
      const m = resolved[key] as Exclude<ResolvedMetadata, { type: 'Mass' | 'Price' }> | undefined;
      acc[`${displayName}`] = m?.entry.toString() ?? ''
    }

    return acc
  }, {} as Record<string, string>)
}
